import React from "react"

import Seo from "../components/seo"
import { motion } from "framer-motion"
import { motionItem } from "../utils/motion"

const Kontakt = () => {
  return (
    <>
      <Seo title="Kontakt" />
      <motion.h1 variants={motionItem}>Kontaktdaten</motion.h1>
      <motion.p variants={motionItem}>
        Julian Berger
        <br />
        Friedrich-Kaiser-Gasse 73/1
        <br />
        1160 Wien, Österreich
        <br />
        <br />
        Tel.: <a href="tel:004367763823220">0043 677 638 232 20</a>
        <br />
        E-Mail:{" "}
        <a href="mailto:kontakt@julianberger.de">kontakt@julianberger.de</a>
      </motion.p>
      <motion.p variants={motionItem}>UID: ATU77689645</motion.p>
    </>
  )
}

export default Kontakt
